/**
 * 小程序后台设置页
 */
 import axios from "@/config/httpConfig";


 // 查询品牌故事或消息
 export function storyOrMessage(data) {
   return axios.post("/miniappshomepage/queryStoryOrMessage",data);
 }
 // 保存品牌故事或消息
 export function saveStoryorMessage(data) {
   return axios.post("/miniappshomepage/saveStoryorMessage",data);
 }
 // 删除品牌故事或消息
 export function deleteStoryOrMessage(data) {
   return axios.post("/miniappshomepage/deleteStoryOrMessage",data);
 }
 // 消息或品牌故事详情
 export function StoryOrMessageDetail(data) {
   return axios.post("/miniappshomepage/StoryOrMessageDetail",data);
 }
 
 /**
  * 绑定小程序
  * 保存cloudcc管理员用户id
  */
 export function editWeixinUser(data) {
   return axios.post("/weixinapp/editWeixinUser",data);
 }
 /**
  * 获取用户界面设置
  * 获取 orgid
  */
 export function getUserSetup(data) {
   return axios.get("/user/getUserSetup",data);
 }
 // 查询轮播图列表 
 export function queryCarouselList(data) {
   return axios.post("/miniappshomepage/queryCarouselList",data);
 }
 // 删除轮播图
 export function deleteCarousel(data) {
   return axios.post("/miniappshomepage/deleteCarousel",data);
 }
 // 保存轮播图
 export function savecarousel(data) {
   return axios.post("/miniappshomepage/savecarousel",data);
 }
 // 轮播图详情
 export function getcarousel(data) {
   return axios.post("/miniappshomepage/CarouselDetail",data);
 }
 // 不带图片更新轮播图
 export function updatecarousel(data) {
   return axios.post("/miniappshomepage/updateCarouselWithoutPic",data);
 }
 // 查询cloudcc管理员用户
 export function getWeixinUser(data) {
   return axios.post("/weixinapp/getWeixinUser",data);
 }
 