<template>
  <div class="mini_program_body" v-loading="loading">
    <left-big-title>{{
      $t("label.setup.mini.msg")
    }}</left-big-title>
    <div class="operation">
      <div class="name">
        <div class="name_left">
          <span class="name_a">*</span>
          <span class="name_name">{{ $t("label.emailtocloudcc.name") }}：</span>
        </div>
        <input type="text" class="name_input" v-model="nameInput" />
      </div>
      <div class="introduce">
        {{ $t("label.service.miniProgram.advertising") }}
      </div>
      <div class="message">
        <div class="message_left">
          <span class="message_message">{{ $t("label.service.miniProgram.intro") }}:</span>
        </div>
        <el-input
          type="textarea"
          :rows="3"
          v-model="textarea"
          class="text_input"
        >
        </el-input>
      </div>
      <div class="radio">
        <div class="radio_left">
          <el-radio v-model="radio" label="1" @change="contentCheck(radio)">{{
            $t("label.chatter.content")
          }}</el-radio>
        </div>
        <div class="radio_text">
          <el-input
            type="textarea"
            :rows="3"
            v-if="textShow"
            v-model="content"
          ></el-input>
        </div>
      </div>
      <div class="radio">
        <div class="radio_left">
          <el-radio v-model="radio" label="2" @change="contentCheck(radio)"
            >{{ $t("label.service.miniProgram.jumpLink") }}</el-radio
          >
        </div>
        <div class="radio_text">
          <input
            type="text"
            class="radio_input"
            v-model="jumpUrl"
            v-if="!textShow"
          />
        </div>
      </div>
      <div class="pitch">
        <div class="pitch_left">
          {{ $t("label.emailtocloudcc.filed.type") }}：
        </div>
        <el-radio v-model="typeRadio" label="1">{{ $t("label.service.miniProgram.store") }}</el-radio>
        <el-radio v-model="typeRadio" label="2">{{ $t("chatter.news") }} </el-radio>
      </div>
      <div class="pitch">
        <div class="pitch_left">{{ $t("label.service.miniProgram.isTop") }}:</div>
        <el-radio v-model="isTop" label="1">{{
          $t("label.tabpage.yesz")
        }}</el-radio>
        <el-radio v-model="isTop" label="0">{{
          $t("label.tabpage.noz")
        }}</el-radio>
      </div>
      <div class="pitch">
        <div class="pitch_left">{{ $t("label.service.miniProgram.isOpen") }}:</div>
        <el-radio v-model="isOpen" label="1">{{
          $t("label.tabpage.yesz")
        }}</el-radio>
        <el-radio v-model="isOpen" label="0">{{
          $t("label.tabpage.noz")
        }}</el-radio>
      </div>
      <div class="empty">
        <button class="button" @click="empty">
          {{ $t("label.forecast.customforecast.button.submit") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import leftBigTitle from "./components/leftBigTitle";
import { saveStoryorMessage, StoryOrMessageDetail } from "./api";
export default {
  data() {
    return {
      loading: false,
      textarea: "",
      radio: "1",
      typeRadio: "1", //类型
      isTop: "1", //是否置顶
      isOpen: "1", //是否开启
      textShow: true, //内容框默认显示
      type: null,
      nameInput: "",
      content: "",
      jumpUrl: "",
      id: "",
    };
  },
  created() {
    this.type = this.$route.params.type;
    this.id = this.$route.params.id;
  },
  mounted() {
    // 如果是编辑  则调用详情接口 数据回显
    if (this.type == "edit") {
      this.getDetail();
    }
  },
  methods: {
    // 详情接口
    async getDetail() {
      let obj = {
        id: this.id,
      };
      let result = await StoryOrMessageDetail(obj);
      let dataObj = result.data;
      // 名称
      this.nameInput = dataObj.name;
      // 简介
      this.textarea = dataObj.resume;
      // 内容还是跳转链接
      if (dataObj.contenttype == "1") {
        // 显示内容
        this.textShow = true;
        this.radio = "1";
        this.content = dataObj.content;
      } else {
        this.textShow = false;
        this.radio = "2";
        this.jumpUrl = dataObj.content;
      }
      // 类型回显
      if (dataObj.type == "1") {
        this.typeRadio = "1";
      } else {
        this.typeRadio = "2";
      }
      // 启用回显
      if (dataObj.top == "1") {
        this.isTop = "1";
      } else {
        this.isTop = "0";
      }
      // 置顶回显
      if (dataObj.isactive == "1") {
        this.isOpen = "1";
      } else {
        this.isOpen = "0";
      }
    },
    contentCheck(radio) {
      if (radio == "1") {
        this.textShow = true;
      } else if (radio == "2") {
        this.textShow = false;
      }
    },
    empty() {
      this.saveDetail();
    },
    // 保存详情的接口
    
    async saveDetail() {
      if (this.nameInput == "") {
        this.$message({
          showClose: true,
          type: "warning",
          message:this.$i18n.t('label.service.miniProgram.name'),//请填写名称
        });
        return;
      }
      if (this.textShow && this.content == "") {
        this.$message({
          showClose: true,
          type: "warning",
          message: this.$i18n.t('label.service.miniProgram.content'),//请填写内容
        });
        return;
      }
      if (!this.textShow && this.jumpUrl == "") {
        this.$message({
          showClose: true,
          type: "warning",
          message: this.$i18n.t('label.service.miniProgram.fillJumpLink'),//请填写跳转链接
        });
        return;
      }
      this.loading = true;
      let obj = {};
      let content = null;
      let contenttype = null;
      if (this.textShow) {
        content = this.content;
        contenttype = 1;
      } else {
        content = this.jumpUrl;
        contenttype = 2;
      }
      obj = {
        // 名称
        name: this.nameInput,
        // 简介
        resume: this.textarea,
        contenttype,
        content,
        type: this.typeRadio,
        top: this.isTop,
        isactive: this.isOpen,
      };
      if (this.type == "edit") {
        obj.id = this.id;
      }
      let result = await saveStoryorMessage(obj);
      if (result.result) {
        this.loading = false;
        if (this.type == "edit") {
          this.$message({
            showClose: true,
            type: "success",
            message: this.$i18n.t(
              "label.setup.mobile.bottombutton.change.success"
            ),
          });
        } else if (this.type == "add") {
          this.$message({
            showClose: true,
            type: "success",
            message: this.$i18n.t("vue_label_notice_add_success"),
          });
        }
        this.$router.go(-1);
      }
    },
  },

  components: {
    leftBigTitle,
  },
};
</script>

<style lang="scss" scoped>
@import url("./style.css");
.name {
  display: flex;
  align-items: center;
  margin-top: 24px;
  .name_a {
    color: #c12218;
  }
  .name_left {
    width: 77px;
    height: 19px;
    margin-left: 10px;
    text-align: right;
    margin-right: 20.2px;
    // display: flex;
    // flex-direction: column;
  }
  .name_name {
    font-size: 14px;
    color: #151515;
    font-weight: 700;
  }
  .name_input {
    outline: none;
    background: #ffffff;
    border: 1px solid #dcdcdc;
    border-radius: 3px;
    width: 325px;
    height: 30px;
    text-indent: 10px;
  }
}

.message {
  display: flex;
  height: 87px;
  margin-top: 24px;
  .el-textarea {
    width: 332px;
    height: 37px;
  }
  .message_a {
    color: #c12218;
  }
  .message_left {
    width: 77px;
    height: 19px;
    margin-left: 10px;
    text-align: right;
    margin-right: 16.2px;
  }
  .message_message {
    font-size: 14px;
    color: #151515;
    font-weight: 700;
  }
  .message_input {
    outline: none;
    background: #ffffff;
    border: 1px solid #dcdcdc;
    border-radius: 3px;
    width: 325px;
    height: 30px;
    text-indent: 10px;
  }
}

.introduce {
  font-size: 12px;
  color: #888888;
  margin-left: 107.5px;
  margin-top: 4px;
}
.el-radio__input.is-checked .el-radio__inner {
  border-color: #006dcc;
  background: #006dcc;
}
.el-radio__input.is-checked + .el-radio__label {
  color: #006dcc;
}
.radio {
  margin-top: 10px;
  .radio_left {
    padding-left: 58px;
    margin-bottom: 4px;
    .el-radio {
      .el-radio__label {
        margin-left: 26px;
      }
    }
  }
  .radio_text {
    margin-left: 100px;

    .el-textarea {
      width: 332px;
    }
  }
  .radio_input {
    outline: none;
    background: #ffffff;
    border: 1px solid #dcdcdc;
    border-radius: 3px;
    width: 325px;
    height: 30px;
    text-indent: 10px;
  }
}
.pitch {
  display: flex;
  margin-top: 21px;
  .pitch_left {
    width: 77px;
    height: 19px;
    margin-left: 10px;
    text-align: right;
    margin-right: 20.2px;
    font-weight: 700;
  }
  .el-radio {
    width: 70px;
  }
}
.empty {
  margin-top: 41px;
  margin-left: 20px;
  .button {
    background: #006dcc;
    width: 83px;
    height: 32px;
    font-size: 14px;
    color: #ffffff;
    outline: none;
    border: 1px solid #006dcc;
    border-radius: 3px;
  }
}
</style>