<template>
  <div class="slot_title">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.slot_title {
  font-size: 20px;
  color: #333333;
  letter-spacing: 0;
  font-weight: bold;
  margin-bottom: 20px;
}
</style>